exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-boilerplate-js": () => import("./../../../src/pages/boilerplate.js" /* webpackChunkName: "component---src-pages-boilerplate-js" */),
  "component---src-pages-concepts-js": () => import("./../../../src/pages/concepts.js" /* webpackChunkName: "component---src-pages-concepts-js" */),
  "component---src-pages-curriculum-3-5-integrated-units-js": () => import("./../../../src/pages/curriculum/3-5-integrated-units.js" /* webpackChunkName: "component---src-pages-curriculum-3-5-integrated-units-js" */),
  "component---src-pages-curriculum-6-8-integrated-units-js": () => import("./../../../src/pages/curriculum/6-8-integrated-units.js" /* webpackChunkName: "component---src-pages-curriculum-6-8-integrated-units-js" */),
  "component---src-pages-curriculum-bootstrap-data-science-js": () => import("./../../../src/pages/curriculum/bootstrap-data-science.js" /* webpackChunkName: "component---src-pages-curriculum-bootstrap-data-science-js" */),
  "component---src-pages-curriculum-creative-computing-js": () => import("./../../../src/pages/curriculum/creative-computing.js" /* webpackChunkName: "component---src-pages-curriculum-creative-computing-js" */),
  "component---src-pages-curriculum-creative-web-js": () => import("./../../../src/pages/curriculum/creative-web.js" /* webpackChunkName: "component---src-pages-curriculum-creative-web-js" */),
  "component---src-pages-curriculum-critical-computing-js": () => import("./../../../src/pages/curriculum/critical-computing.js" /* webpackChunkName: "component---src-pages-curriculum-critical-computing-js" */),
  "component---src-pages-curriculum-cs-a-js": () => import("./../../../src/pages/curriculum/cs-a.js" /* webpackChunkName: "component---src-pages-curriculum-cs-a-js" */),
  "component---src-pages-curriculum-cs-and-the-city-js": () => import("./../../../src/pages/curriculum/cs-and-the-city.js" /* webpackChunkName: "component---src-pages-curriculum-cs-and-the-city-js" */),
  "component---src-pages-curriculum-cs-discoveries-js": () => import("./../../../src/pages/curriculum/cs-discoveries.js" /* webpackChunkName: "component---src-pages-curriculum-cs-discoveries-js" */),
  "component---src-pages-curriculum-cs-principles-js": () => import("./../../../src/pages/curriculum/cs-principles.js" /* webpackChunkName: "component---src-pages-curriculum-cs-principles-js" */),
  "component---src-pages-curriculum-exploring-cs-js": () => import("./../../../src/pages/curriculum/exploring-cs.js" /* webpackChunkName: "component---src-pages-curriculum-exploring-cs-js" */),
  "component---src-pages-curriculum-intro-to-computational-media-js": () => import("./../../../src/pages/curriculum/intro-to-computational-media.js" /* webpackChunkName: "component---src-pages-curriculum-intro-to-computational-media-js" */),
  "component---src-pages-curriculum-intro-to-pcomp-js": () => import("./../../../src/pages/curriculum/intro-to-pcomp.js" /* webpackChunkName: "component---src-pages-curriculum-intro-to-pcomp-js" */),
  "component---src-pages-curriculum-js": () => import("./../../../src/pages/curriculum.js" /* webpackChunkName: "component---src-pages-curriculum-js" */),
  "component---src-pages-curriculum-k-2-integrated-units-js": () => import("./../../../src/pages/curriculum/k-2-integrated-units.js" /* webpackChunkName: "component---src-pages-curriculum-k-2-integrated-units-js" */),
  "component---src-pages-curriculum-software-engineering-program-jr-js": () => import("./../../../src/pages/curriculum/software-engineering-program-jr.js" /* webpackChunkName: "component---src-pages-curriculum-software-engineering-program-jr-js" */),
  "component---src-pages-curriculum-software-engineering-program-js": () => import("./../../../src/pages/curriculum/software-engineering-program.js" /* webpackChunkName: "component---src-pages-curriculum-software-engineering-program-js" */),
  "component---src-pages-curriculum-tangible-interfaces-js": () => import("./../../../src/pages/curriculum/tangible-interfaces.js" /* webpackChunkName: "component---src-pages-curriculum-tangible-interfaces-js" */),
  "component---src-pages-curriculum-teals-intro-to-cs-js": () => import("./../../../src/pages/curriculum/teals-intro-to-cs.js" /* webpackChunkName: "component---src-pages-curriculum-teals-intro-to-cs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outcomes-js": () => import("./../../../src/pages/outcomes.js" /* webpackChunkName: "component---src-pages-outcomes-js" */),
  "component---src-pages-perspectives-js": () => import("./../../../src/pages/perspectives.js" /* webpackChunkName: "component---src-pages-perspectives-js" */),
  "component---src-pages-post-template-js": () => import("./../../../src/pages/post-template.js" /* webpackChunkName: "component---src-pages-post-template-js" */),
  "component---src-pages-practices-js": () => import("./../../../src/pages/practices.js" /* webpackChunkName: "component---src-pages-practices-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-what-is-cs-js": () => import("./../../../src/pages/what-is-cs.js" /* webpackChunkName: "component---src-pages-what-is-cs-js" */)
}

